<template>
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <template v-if="isNewClient">
            <div class="text-base leading-tight tracking-wide text-primary text-center">
                {{ formData.email }}
            </div>
            <div class="mt-2 border-0 border-b border-solid border-gray-200 pb-2">
                <h6 class="text-base font-medium leading-tight tracking-wide text-gray-800 md:text-base">Selected Account</h6>
                <div class="mt-2 mb-4">
                    <dropdown-base
                        maxWidth="100%"
                        placeholder="Select Account"
                        :searchable="true"
                        width="100px"
                        minWidth="100%"
                        :options="loginStates.clients"
                        :config="{ label: 'client_name', trackBy: 'id' }"
                        :value="formData.clients"
                        :disabled="true"
                    />
                </div>
            </div>
        </template>
        <h1 class="text-xl font-semibold leading-tight text-center tracking-wide text-gray-800 md:text-2xl" v-else>Forgot Password</h1>
        <div class="space-y-2 md:space-y-4">
            <div>
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text">Password</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password here"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                    required=""
                    @input="onInput($event.target.value, 'password')"
                />
            </div>
            <div>
                <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text">Confirm Password</label>
                <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="Enter Password here"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                    required=""
                    @input="onInput($event.target.value, 'confirmPassword')"
                    @keyup.enter="onSubmit()"
                />
            </div>
            <p class="text-2xs font-thin text-red-500" :class="{ 'hidden_validation invisible': !formData.password || !errors.validationGroup['formData.password'].$invalid }">
                <template v-if="isNotAllowedSymbol">
                    {{ notAllowedCharMsg }}
                </template>
                <template v-else>
                    Password must contain atleast 12 characters, a lower case letter, an uppercase letter, a number and a special character.
                </template>
            </p>
            <div class="text-2xs font-thin text-red-500" :class="{ 'hidden_validation invisible': !formData.confirmPassword || errors.formData.confirmPassword.sameAsPassword }">Password and Confirm Password should match</div>
            <button :disabled="errors.validationGroup.$invalid" type="submit" class="otp w-full text-white-text bg-primary hover:bg-primary-focus focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" @click="onSubmit()">{{ isNewClient ? "Login" : "Reset Password" }}</button>
        </div>
    </div>
</template>

<script>
import DropdownBase from "@shared/components/dropdown-base";
import { getLoginInfo} from "./../../services";

export default {
    name: "reset-password",
    data() {
        return {
            notAllowedRegex: /[`~^()\-_=+/[\]{}\\\\:;'"“<>,.\s]/,
            notAllowedCharMsg: 'Following characters not allowed: ` ~ ^ ( ) - _ = + / [ ] { } : ; \' " “ < > , . and a space'
        }
    },
    computed: {
        isNotAllowedSymbol() {
            return this.notAllowedRegex.test(this?.formData?.password);
        },
    },
    props: {
        formData: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => {},
        },
        isNewClient: {
            type: Boolean,
            default: false,
        },
        loginStates: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onSubmit() {
            if (!this.errors.validationGroup.$invalid) {
                this.$emit("on-submit", { component: "resetState" });
            }
        },
        onInput(event, type) {
            const value = typeof event === 'string' ? event.trim() : event
            this.$emit("on-change", { type, value });
        },
    },
    components: {
        DropdownBase,
    },
    mounted() {
        getLoginInfo();
    }
};
</script>

<style lang="scss" scoped>
.hidden_validation {
    margin-top: -1rem !important;
}
::v-deep {
    .dropdown {
        border-color: #DDDDDD;
        background-color: rgba(249, 250, 251, 1);
    }
}
</style>
